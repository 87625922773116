<script lang="ts">
  let {
    name,
    options,
    group = $bindable(),
    required = false,
    error,
  }: {
    name: string;
    options: string[][];
    group: string;
    required: boolean;
    error: string;
  } = $props();
</script>

{#each options as [text, value]}
  <label class="label-text flex flex-row gap-2">
    <input
      type="radio"
      class="radio"
      class:input-error={!!error}
      {name}
      {value}
      bind:group={group}
      {required}
    />
    <span>{text}</span>
  </label>
{/each}

{#if error}
  <div class="text-error">{error}</div>
{/if}
