import { stringStorage } from "../stores/storage";

export type CookieConsentType = "all" | "necessary" | null;

export const cookieConsent = stringStorage<CookieConsentType>("cookieConsent")

export const analyticsOptedIn = new Promise((resolve, _) => {
  cookieConsent.subscribe((v: CookieConsentType)=>{
    if(v === "all") resolve(true)
  })
});
