<script lang="ts" context="module">
  export type MaybeHtml = string | {html: string}
</script> 
<script lang="ts">
  let { value } : { value : MaybeHtml} = $props();
</script>
{#if typeof value === "object" && value.html}
  {@html value.html}
{:else}
  {value}
{/if}