/*
 * PromiseQueue executes Promise-returning functions one at a time with minimal
 * code change at the calling site.
 * 
 * getQueue(key) allows scripts from different files conveniently to share a
 * specific queue.
 * 
 * Example PromiseQueue usage:
 * 
 * const twoSec = ()=>new Promise((r, _)=>setTimeout(()=>r(Date.now()), 2_000))
 * async function simultaneous() {
 *   let start = Date.now()
 *   return await Promise.all([
 *     twoSec().then(t=>t - start),
 *     twoSec().then(t=>t - start)
 *   ])
 * }
 * await simultaneous()
 * // [2001, 2002]
 * 
 * const queue = new PromiseQueue();
 * async function queued() {
 *   let start = Date.now()
 *   return await Promise.all([
 *     queue.add(twoSec).then(t=>t - start),
 *     queue.add(twoSec).then(t=>t - start)
 *   ])
 * }
 * await queued()
 * // [2001, 4002]
 */

export default class PromiseQueue {
  last:Promise<any> = Promise.resolve()
  
  async add(fn: { (): Promise<any>; (): any }){
    const wasLast = this.last
    let resolveLast: (value: any) => void
    this.last = new Promise((resolve, _)=>resolveLast = resolve)
    await wasLast
    try {
      const result = await fn()
      return result;
    } finally {
      resolveLast(true)
    }
  }
}

const queues = new Map<string, PromiseQueue>

function getQueue(key){
  if(queues.has(key)) return queues.get(key);
  const q = new PromiseQueue
  queues.set(key, q)
  return q
}

export { getQueue, PromiseQueue }