<script lang="ts">
  import { stringStorage } from "../stores/storage";
  import { fly } from 'svelte/transition';

  const {
    countryCodeOptions,
    defaultCountryCode,
    validDestination
  } : {
    countryCodeOptions: [string, string][];
    defaultCountryCode: string;
    validDestination: string;
  } = $props();

  const LoginMethod = {
    Unspecified: null,
    Email: "Email",
    MobilePhone: "MobilePhone",
  } as const

  type LoginMethods = keyof typeof LoginMethod;

  const countryCode = stringStorage("mobilePhoneCountryCode", defaultCountryCode);
  const loginMethod = stringStorage<LoginMethods>("loginMethod", window.innerWidth < 768 ? LoginMethod.MobilePhone : LoginMethod.Email);
  const duration = 500

  // ATTN! id="search_email" and id="search_phone" are there to confuse [Mobile] Safari's
  // heuristics for how to handle autofill on a field. Without this, a solo input gets
  // treated as a likely username field, and Safari suggests usernames or nothing instead
  // of offering contact info autofill. With "search" included in the id, as of 2024-08-21,
  // I'm seeing the desired contact autofill behavior. Hat tip to:
  // https://stackoverflow.com/questions/22661977/disabling-safari-autofill-on-usernames-and-passwords#68260636
</script>

<div class="flex flex-col gap-6 lg:gap-8">
  <div class="grid">
    {#if $loginMethod == LoginMethod.Email}
      <div class="flex flex-col gap-6 lg:gap-8 login-method" transition:fly={{y: 145, duration}}>
        <label class="form-control">
          <div class="label">
            <span class="label-text text-medium text-base leading-none">Email</span>
          </div>
          <input
            autofocus
            type="email"
            id="search_email"
            name="login_form[email]"
            autocomplete="email"
            placeholder="Your Email"
            class="input input-bordered input-primary w-full py-5"
            required
          />
        </label>
        <button type="submit" class="btn btn-primary btn-block">Continue</button>
      </div>
    {:else}
      <div class="flex flex-col gap-6 lg:gap-8 login-method" transition:fly={{y: 145, duration}}>
        <div class="form-control">
          <label class="label" for="search_phone">
            <span class="label-text text-medium text-base leading-none">Mobile Phone on File</span>
          </label>
          <label class="sr-only" for="country_code">Country Code</label>
          <div class="join">
            <select
              id="country_code"
              name="login_form[country_code]"
              bind:value={$countryCode}
              class="join-item pl-2 pr-3 select select-bordered select-primary"
            >
              {#each countryCodeOptions as [value, label]}
                <option value={label}>{value}</option>
              {/each}
            </select>
            <input
              autofocus
              type="tel"
              id="search_phone"
              name="login_form[mobile_phone]"
              autocomplete="tel-national"
              placeholder="Your Phone"
              class="join-item input input-bordered input-primary w-full"
              required
            />
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block">Continue</button>
      </div>
    {/if}
  </div>

  <div class="divider [&:not(:empty)]:gap-3 my-2">
    <div class="bg-[#99d1fd] rounded-full flex items-center justify-center w-9 h-9 shrink-0 font-bold text-base">Or</div>
  </div>

  <div class="grid">
  {#if $loginMethod == LoginMethod.Email}
    <button type="button" class="switch-button" onclick={() => $loginMethod = LoginMethod.MobilePhone} transition:fly={{y: -226, duration: 300}}>
      Log in with mobile phone
    </button>
  {:else}
    <button type="button" class="switch-button" onclick={() => $loginMethod = LoginMethod.Email} transition:fly={{y: -226, duration: 300}}>
      Log in with email
    </button>
  {/if}
  </div>

  <input type="hidden" name="dest" value={validDestination} />
</div>

<style lang="postcss">
  .switch-button {
    @apply btn btn-block font-normal text-base;
    grid-area: 1 / 1;
  }

  .login-method {
    grid-area: 1 / 1;
  }
</style>