import { derived, writable } from "svelte/store";

/*
 * A writable store that persists its value to localStorage (or optionally sessionStorage,
 * though this is less useful, or optionally anything that implements the Storage interface).
 * 
 * Use like writable(initial), except it's stringStorage(key, defaultValue) where `key` is
 * the string key of `localStorage` to read/write and `defaultValue` is the initial value iff
 * nothing is yet stored for `key`.
 * 
 * NOTE: This store supports only string values and null, the only native type in Storage.
 * If you need more flexible values, use jsonStorage.
 */
export function stringStorage<T extends string>(key: string, defaultValue: T = null, options = {storage: localStorage}) {
  const {storage} = options;
  const initialValue = storage.getItem(key) as T ?? defaultValue ?? null;
  const value = writable<T>(initialValue);
  const storageEventListener = (e: StorageEvent)=>{
    if(e.key !== key && e.key !== null) return; // ignore change to another key (null key indicates entire storage area cleared, so we care about it)
    if(e.storageArea !== storage) return; // ignore change to localStorage if this store uses sessionStorage
    value.set(e.newValue as T);
  }
  const reader = derived(value, ($value, set)=>{
    set($value);
    window.addEventListener("storage", storageEventListener);
    return ()=>window.removeEventListener("storage", storageEventListener);
  }, initialValue);
  return {
    set: (v: T)=>{
      if(v == null) { // null or undefined
        storage.removeItem(key)
        value.set(null)
      } else {
        storage.setItem(key, v)
        value.set(v)
      }
    },
    subscribe: reader.subscribe
  }
}

/*
 * Writable store just like stringStorage above, but accepts any value that can be represented as JSON.
 * 
 * WARNING: This store is not yet used and has not been tested. Remove this warning once you use it.
 */
export function jsonStorage(key: string, defaultValue: JSONValue = null, options = {storage: localStorage}) {
  const parse = (v: string | null)=>v == null ? null : JSON.parse(v)
  const stringify = (v: JSONValue)=>v == null ? null : JSON.stringify(v)
  const storage = stringStorage(key, stringify(defaultValue), options)
  const reader = derived(storage, parse);
  return {
    set: (v: JSONValue)=>storage.set(stringify(v)),
    subscribe: reader.subscribe
  }
}

type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[];