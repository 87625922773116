import "@hotwired/turbo-rails"
import "../controllers"
import dialogPolyfill from 'dialog-polyfill'

window.dialogPolyfill = dialogPolyfill

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import registerSvelte from "../lib/registerSvelte.svelte"
import Alert from "../components/Alert.svelte"
import CaseTypeSwitcher from "../components/CaseTypeSwitcher.svelte"
import Navigation from "../components/Navigation.svelte"
import RevealDocuments from "../components/RevealDocuments.svelte"
import CropImage from "../components/CropImage.svelte"
import TasksToDoNotice from "../components/TasksToDoNotice.svelte"
import IdentifyFinanceCompanyTask from "../tasks/IdentifyFinanceCompanyTask.svelte"
import LeaseEndDateTask from "../tasks/LeaseEndDateTask.svelte"
import UploadFinanceAgreementTask from "../tasks/UploadFinanceAgreementTask.svelte"
import SharepointFileInput from "../components/SharepointFileInput.svelte"
import SharePCP from "../components/SharePCP.svelte"
import AdditionalDocument from "../components/AdditionalDocument.svelte"
import Login from "../components/Login.svelte"
import CookieBanner from "../components/CookieBanner.svelte"
import CookieConsentForm from "../components/CookieConsentForm.svelte"
import ContactPreferences from "../components/ContactPreferences.svelte"
import Dismissable from "../components/Dismissable.svelte"
registerSvelte({
  Alert,
  Navigation,
  RevealDocuments,
  CaseTypeSwitcher,
  CropImage,
  SharepointFileInput,
  TasksToDoNotice,
  IdentifyFinanceCompanyTask,
  LeaseEndDateTask,
  UploadFinanceAgreementTask,
  SharePCP,
  AdditionalDocument,
  Login,
  CookieBanner,
  CookieConsentForm,
  ContactPreferences,
  Dismissable
})

import onElementExists from "../lib/onElementExists"
// Auto-open any dialog that appears in the DOM and isn't designed to be controlled by modal_dialog_controller
onElementExists("dialog[data-auto-open]", dialog => {
  window.dialogPolyfill.registerDialog(dialog) // Launchpad Labs code used this; I haven't investigated how necessary it is.
  dialog.showModal()
  // Could return a function here that would close the dialog if it left the DOM, but
  // as I read https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog, close
  // is not fired if user exits with <Esc>, and as an approximation, the element being
  // removed from the DOM without closing seems more like an <Esc>.
})
import "../lib/posthog"