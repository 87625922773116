import posthog from "posthog-js";
import onElementExists from "./onElementExists";
import { analyticsOptedIn } from "./cookieConsent";

analyticsOptedIn.then(() => startAnalytics()).catch(() => {});

function startAnalytics() {
  onElementExists("html[data-posthog-api-key]", (html: HTMLElement) => {
    const posthogApiKey = html.dataset.posthogApiKey;
    const posthogId = html.dataset.posthogId;

    posthog.init(posthogApiKey, {
      api_host: "https://eu.i.posthog.com",
      disable_persistence: true, // Disables cookies, session storage and local storage
      disable_surveys: true,
      persistence: "memory",
      bootstrap: {
        distinctID: posthogId,
      },
      session_recording: {
        maskTextSelector: "*", // Masks all text elements
        maskAllInputs: true,
      },
    });

    addEventListener("turbo:load", () => {
      posthog.capture("$pageview");
    });

    addEventListener("turbo:visit", () => {
      // Takes place when Turbo link is clicked, before the new page is loaded
      posthog.capture("$pageleave");
    });
  });
}
