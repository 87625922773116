<script lang="ts">
  import { onMount } from 'svelte';

  type Props = {
    src: string;
    toleranceThreshold?: number;
    padding?: number;
    backgroundColor?: string;
    [key: string]: any; // Allow any additional props to pass through to img
  };

  const props: Props = $props();
  const {
    src,
    toleranceThreshold = 2,
    padding = 8,
    backgroundColor = "white",
    ...restProps
  } = props;

  let canvas: HTMLCanvasElement;
  let croppedImageSrc = $state<string>(`data:image/svg+xml;utf8,<svg version="1.1" width="1" height="1" xmlns="http://www.w3.org/2000/svg"></svg>`);

  const processImage = async () => {
    if (!canvas || !src) return;

    const originalImage = new Image();
    originalImage.crossOrigin = "anonymous";

    try {
      // Download the image
      await new Promise((resolve, reject) => {
        originalImage.onload = resolve;
        originalImage.onerror = reject;
        originalImage.src = src;
      });

      const ctx = canvas.getContext('2d')!;

      // Draw original image and get its data
      const width = canvas.width = originalImage.width;
      const height = canvas.height = originalImage.height;
      // Fill with backgroundColor in case of transparency in image and to make it easy to get the RGB values
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      const colorToTrim = ctx.getImageData(0, 0, 1, 1).data.slice(0, 3); // RGB values of backgroundColor (could be specified as any CSS color)
      ctx.drawImage(originalImage, 0, 0);
      // Extract the image data from the canvas
      const data = ctx.getImageData(0, 0, width, height).data

      let left = width, right = 0, top = height, bottom = 0;

      // Loop through all pixels to find the bounding box
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const i = (y * width + x) * 4;
          const distance = colorDistance(data.slice(i, i + 3), colorToTrim);
          if (distance > toleranceThreshold) {
            left = Math.min(left, x);
            right = Math.max(right, x);
            top = Math.min(top, y);
            bottom = Math.max(bottom, y);
          }
        }
      }

      // Crop image
      left = Math.max(0, left - padding);
      right = Math.min(width - 1, right + padding);
      top = Math.max(0, top - padding);
      bottom = Math.min(height - 1, bottom + padding);

      const croppedWidth = right - left + 1;
      const croppedHeight = bottom - top + 1;

      // Draw cropped image
      canvas.width = croppedWidth;
      canvas.height = croppedHeight;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, croppedWidth, croppedHeight);
      ctx.drawImage(originalImage, left, top, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
      const blob = await new Promise<Blob>((resolve, _)=>canvas.toBlob(resolve));
      croppedImageSrc = URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  onMount(() => {
    if (src) {
      processImage();
      return ()=>URL.revokeObjectURL(croppedImageSrc);
    }
  });

  // Find the largest difference between the RGB values of two colors
  // There are many different ways to compare colors, but we don't need anything fancy
  function colorDistance(color1: Uint8ClampedArray, color2: Uint8ClampedArray): number {
    return Math.max(
      Math.abs(color1[0] - color2[0]),
      Math.abs(color1[1] - color2[1]),
      Math.abs(color1[2] - color2[2])
    );
  }
</script>

<canvas bind:this={canvas} style="display: none;"></canvas>
<div style="--cropped: url(&quot;{croppedImageSrc}&quot;)"><img
  {src}
  {...restProps}
  crossorigin="anonymous"
/></div>

<style>
  div {
    display: contents;
  }
  img {
    object-position: 2000px 2000px;
    background-image: var(--cropped);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>