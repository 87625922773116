<script lang="ts">
let {
  label,
  documents
} : {
  label: string,
  documents: Array<[string, string, number]>
} = $props()

let open = $state(false)
</script>
<div class="rounded bg-neutral-100 -mx-2 grid grid-cols-1 auto-rows-min">
  <div class="text-sm p-2 row-start-1 col-start-1 flex items-center gap-2">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4 inline-block transition-transform" class:rotate-90={open}>
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
    <span>{label} ({documents.length})</span>
  </div>
  <input type="checkbox" bind:checked={open} class="appearance-none border-0 bg-transparent row-start-1 opacity-0 col-start-1 !w-full !h-full cursor-pointer">
  <div class:hidden={!open} class="text-sm">
    {#each documents as [name, type, created]}
      {@const datetime = new Date(created * 1000)}
      <div class="p-2 border-t border-t-neutral-200 border-solid first:border-t-0 first:pt-0">
        <b class="break-all">{name}</b>
        <div class="flex justify-between flex-wrap">
          <span>{type}</span>
          <span class="text-right flex-grow">{datetime.toLocaleDateString()}</span>
        </div>
      </div>
    {/each}
  </div>
</div>