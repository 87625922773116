import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tabSelector", "tabContent"]
  static classes = ["activeTabSelector", "inactiveTabContent"]

  showTab(event) {
    const selectedTabId = event.currentTarget.dataset.tabId

    // set the active tab button
    this.tabSelectorTargets.forEach((tabSelector) => {
      tabSelector.classList.toggle(this.activeTabSelectorClass, tabSelector.dataset.tabId == selectedTabId)
    })

    // show selected tab content
    this.tabContentTargets.forEach((tabContent) => {
      tabContent.classList.toggle(this.inactiveTabContentClass, tabContent.dataset.tabId != selectedTabId)
    })
  }
}
