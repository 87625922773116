<script lang="ts">
import { tokenHash } from "../lib/dom";
import type { Snippet } from 'svelte';

let {
  button,
  children,
  open = $bindable(false),
  position = "start bottom",
  width = "w-52",
  margin = "mt-1",
  background = "bg-base-200",
  color = "text-black",
  class: className
} : {
  button: Snippet;
  children: Snippet;
  open?: boolean;
  position?: string; // space-separated top or bottom, start or end
  width?: string;
  margin?: string;
  background?: string;
  class?: string;
} = $props();

let p = $derived(tokenHash(position))
let menu = $state()
const handleClick = (e: MouseEvent) => {
  if (open && menu && !menu.contains(e.target as Node)) {
    e.stopPropagation()
    e.preventDefault()
  }
  open = false
}
</script>
<svelte:window on:click={handleClick} />
<details bind:open={open} class="dropdown {className}" class:dropdown-top={p.top} class:dropdown-end={p.end} class:dropdown-bottom={p.bottom} class:dropdown-start={p.start}>
  <summary class="contents cursor-pointer">{@render button()}</summary>
  <ul bind:this={menu} class="dropdown-content menu rounded-lg z-[1] {margin} {width} {background} {color} p-2 shadow">
    {@render children()}
  </ul>
</details>
