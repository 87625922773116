<script lang="ts">
  import Dialog from "./Dialog.svelte";
  import SharepointFileInput from "./SharepointFileInput.svelte";
  import Form from "./Form.svelte";
  import Paperclip from "~icons/ph/paperclip";

  let {
    matterUrls,
    allowedExtensions,
    errors,
  }: {
    matterUrls: [string, string[]];
    allowedExtensions: string[];
    errors: string[];
  } = $props();

  let dialog = $state<Dialog>();
  let dialogId = $state(0); // Reset Dialog upon closing to allow user to chose other matter
  let fileInput = $state<SharepointFileInput>();
  let selectedMatterUrls = $state(matterUrls.length == 1 ? matterUrls[0][1] : "");
  let [createUploadSessionUrl, attachDocumentsUrl] = $derived(selectedMatterUrls || ["",""]);

  function handleClose(_) {
    fileInput?.cancelAll()
    dialogId += 1;
    selectedMatterUrls = "";
    errors = [];
  }
</script>

<div class="upload rounded bg-white p-4 lg:p-6">
  <h2 class="text-2xl leading-7 font-medium mb-2">Additional Documents</h2>
  <p class="mb-4 text-base">
    Have more items to share? Upload any additional documents, records, or
    photos that may help your case.
  </p>

  <div class="flex justify-center">
    <button
      class="btn btn-sm mx-auto text-sm text-primary font-medium rounded-lg"
      onclick={() => dialog.showModal()}
    >
      Upload Documents
      <Paperclip class="w-6 h-6" />
    </button>
  </div>
</div>

{#key dialogId}
  <Dialog bind:this={dialog} onclose={handleClose} showClose>
    <Form action={attachDocumentsUrl} method="post" class="flex flex-col gap-4">
      <h2 class="font-medium text-xl">Upload Additional Documents</h2>
      {#if matterUrls.length > 1}
        <select
          bind:value={selectedMatterUrls}
          class="select select-bordered w-full"
          required
          disabled={fileInput?.hasFiles}
        >
          <option value="">Which case is this document for?</option>
          {#each matterUrls as [label, urls]}
            <option value={urls}>{label}</option>
          {/each}
        </select>
      {/if}
      {#if createUploadSessionUrl}
        <SharepointFileInput
          bind:this={fileInput}
          name={"additional_document"}
          accept={allowedExtensions}
          {createUploadSessionUrl}
          required={true}
          multiple={true}
        />
        <input
          name="optional_note"
          type="text"
          placeholder="Add a note (optional)"
          class="input input-bordered w-full"
        />
        {#if errors?.length}
          <div class="text-error">{errors.join(". ")}</div>
        {/if}
      {/if}
      <div class="flex justify-between">
        <button class="btn btn-primary" type="submit">Send</button>
        <button class="btn btn-ghost" type="button" onclick={()=>dialog.close()}>Cancel</button>
      </div>
    </Form>
  </Dialog>
{/key}
