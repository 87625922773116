<script lang="ts">
import Dropdown from './Dropdown.svelte';
import Caret from "~icons/ph/caret-down-fill";
import Check from "~icons/ph/check-bold";

let {
  case_types,
  todo_ids
} : {
  case_types: {id: string, label: string, path: string, current: boolean}[];
  todo_ids: { [key: string]: string };
} = $props()

let caseType = $derived(case_types.find(({current})=>current));
const todoCount = (caseTypeId, invert=false)=>Object.values(todo_ids).filter(v=>(v===caseTypeId) !== invert).length
let otherTodos = $derived(todoCount(caseType.id, true))
</script>

{#snippet title()}
  <h1 class="font-normal text-lg whitespace-nowrap text-white">Your {caseType.label}</h1>
{/snippet}

{#if case_types.length === 1}
  {@render title()}
{:else}
  <Dropdown position="end bottom" margin="mt-1 -mr-2.5">
    {#snippet button()}
      <div class="flex items-center gap-2">
        {@render title()}
        <div class="btn btn-xs btn-circle bg-white" class:todo={!!otherTodos} data-count={otherTodos}>
          <Caret class="w-4 h-4 text-blue" />
        </div>
      </div>
    {/snippet}
    {#each case_types as caseType}
      {@const todos = todoCount(caseType.id)}
      <li><a href={caseType.path} class="no-underline leading-relaxed">
        {#if caseType.current}
          <Check class="w-4 h-4 -ml-2 -mt-0.5 block" />
        {:else}
          <span class="w-4 h-4 -ml-2 -mt-0.5 block"></span>
        {/if}
        {caseType.label}
        {#if todos}
          <span class="rounded-full w-5 h-5 text-xs font-extrabold inline-flex items-center justify-center bg-warning">{todos}</span>
        {/if}
      </a></li>
    {/each}
  </Dropdown>
{/if}

<style lang="postcss">
  .todo {
    @apply outline-2 outline outline-warning;
    &::after {
      content: attr(data-count);
      @apply rounded-full w-5 h-5 text-xs font-extrabold flex items-center justify-center bg-warning absolute -right-2.5 -top-2.5;
    }
  }
</style>