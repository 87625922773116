import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide"
export default class extends Controller {
  static targets = ["element", "showButton", "hideButton"]

  showElement() {
    this.show(this.elementTarget)
    this.hide(this.showButtonTarget)
    this.show(this.hideButtonTarget)
  }

  hideElement() {
    this.hide(this.elementTarget)
    this.hide(this.hideButtonTarget)
    this.show(this.showButtonTarget)
  }

  show(target) {
    target.classList.remove("hidden")
  }

  hide(target) {
    target.classList.add("hidden")
  }
}
