<script lang="ts">
  import type { Snippet } from 'svelte';
  let {
    children,
    class: className,
    autoOpen,
    showClose,
    position = "auto",
    ...props
  } : {
    children: Snippet;
    class?: string;
    autoOpen?: boolean;
    showClose?: boolean;
    position?: "auto" | "middle" | "bottom";
  } = $props()
  let dialog = $state<HTMLDialogElement>()

  export function close(v = undefined){
    return dialog?.close(v)
  }

  export function showModal(){
    return dialog?.showModal()
  }
</script>

<dialog bind:this={dialog} data-auto-open={autoOpen || null}
  class="modal"
  class:modal-bottom={position == "auto" || position == "bottom"}
  class:sm:modal-middle={position == "auto"}
  class:modal-middle={position == "middle"}
  {...props}
>
  <div class="modal-box {className}">
    {@render children()}
    {#if showClose}
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>
    {/if}
  </div>
</dialog>
