<script lang="ts">
  import Form from "../components/Form.svelte";
  import SharepointFileInput from "../components/SharepointFileInput.svelte";
  import { domId } from "../lib/dom";
  let {
    action,
    taskTitle,
    createUploadSessionUrl,
    uploadAttachmentExtensions,
    errors,
  }: {
    action: string;
    taskTitle: string;
    createUploadSessionUrl: string;
    uploadAttachmentExtensions: string[];
    errors: { [key: string]: string } | null;
  } = $props();

  let hasAgreement = $state("yes");

  const closeDialogId = domId("close-dialog")
</script>

<div class="label font-medium text-lg pl-0">{taskTitle}</div>

<Form method="patch" {action}>
  <div class="flex gap-4 mb-6">
    <label class="cursor-pointer">
      <input
        type="radio"
        class="radio"
        name="has_agreement"
        value="yes"
        bind:group={hasAgreement}
      />
      <span class="label-text pl-1">Yes</span>
    </label>
    <label class="cursor-pointer">
      <input
        type="radio"
        class="radio"
        name="has_agreement"
        value="no"
        bind:group={hasAgreement}
      />
      <span class="label-text pl-1">No</span>
    </label>
  </div>
  <div class="grid place-items-stretch">
    <div class="row-span-full col-span-full flex flex-col gap-3" class:invisible={hasAgreement !== "yes"}>
      <div class="form-control w-full">
        <SharepointFileInput
          name="finance_agreement"
          accept={uploadAttachmentExtensions}
          {createUploadSessionUrl}
          required={hasAgreement === "yes"}
          multiple={true}
        />
        {#if errors?.finance_agreement}<div class="text-error">{errors.finance_agreement}</div>{/if}
      </div>
      <p class="text-base">
        If you have a PDF or photos of your finance agreement, it will allow us
        to document many of the key facts about your claim.
      </p>
      {#if errors?.general}<div class="text-error">{errors.general}</div>{/if}
      <div class="flex justify-between">
        <button class="btn btn-primary" type="submit">Send</button>
        <button class="btn btn-ghost" form={closeDialogId}>Cancel</button>
      </div>
    </div>

    <div class="row-span-full col-span-full flex flex-col gap-3 items-stretch justify-between" class:invisible={hasAgreement === "yes"}>
      <p class="text-base">
        If you can find your finance agreement, it helps us move your case
        forward. If you can't find it, we can still proceed if you
        provide the name of your finance company.
      </p>
      <div class="w-full flex justify-between">
        <button class="btn btn-primary" type="submit">I don't have it</button>
        <button class="btn btn-ghost" form={closeDialogId}>Cancel</button>
      </div>
    </div>
  </div>
</Form>
<form method="dialog" id={closeDialogId}></form>
