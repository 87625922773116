export function tokenHash(string: string) {
  return string.trim().split(/\s+/).reduce((hash, token)=>{
    hash[token] = true;
    return hash;
  }, {})
}

export function domId(prefix = "id") {
  return `${prefix}-${Math.random().toString(36).substring(2, 15)}`;
}
