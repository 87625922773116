<script lang="ts">
  import Form from "../components/Form.svelte";
  import { domId } from "../lib/dom";
  import FloatingFooter from "./utils/FloatingFooter.svelte";
  import RadioGroup from "./utils/RadioGroup.svelte";

  let {
    action,
    errors,
    financeCompanyOptions,
  }: {
    action: string;
    errors: { [key: string]: string } | null;
    financeCompanyOptions: string[];
  } = $props();

  let selectedOption = $state("yes");
  const closeDialogId = domId("close-dialog");
  const financeCompanyOptionsId = domId("finance-company-options");
</script>

<Form method="put" {action}>
  <div class="flex flex-col gap-3 text-base">
    <div class="font-medium">
      Do you know the name of the finance company for this vehicle?
    </div>

    <RadioGroup
      name="finance_company_known"
      options={[
        ["Yes", "yes"],
        ["No", "no"],
        ["I didn't finance this vehicle: I purchased it outright", "not_financed"]
      ]}
      bind:group={selectedOption}
      required={true}
      error={errors?.finance_company_known || errors?.no_finance_company}
    />

    <div class="grid place-items-stretch text-base">
      <div
        class="row-span-full col-span-full"
        class:invisible={selectedOption !== "yes"}
      >
        <div class="flex flex-col gap-1">
          <div class="font-medium">Finance company name:</div>
          <input
            type="text"
            list={financeCompanyOptionsId}
            name="finance_company_name"
            class="input input-bordered w-full max-w-xs"
            class:input-error={errors?.finance_company_name}
            required={selectedOption === "yes"}
          />
        </div>
        <span class="label-text">Type to search or to enter a new name</span>
        {#if errors?.finance_company_name}<div class="text-error">
            {errors.finance_company_name}
          </div>{/if}
      </div>

      <div
        class="row-span-full col-span-full"
        class:invisible={selectedOption !== "no"}
      >
        <div class="bg-warning-200 rounded px-5 py-3">
          Identifying the finance company is required to submit a claim.
        </div>
      </div>

      <div
        class="row-span-full col-span-full"
        class:invisible={selectedOption !== "not_financed"}
      >
        <div class="bg-info-200 rounded px-5 py-3">
          Car finance mis-selling claims only apply to PCP or hire-purchase
          agreements. If you are sure you did not finance this vehicle, click
          "Done" to request that we close this case.
        </div>
      </div>
    </div>

    <div class="divider my-0"></div>
    <div class="prose text-black">
      <p>
        Correctly identifying the finance company is a critical step in making
        your claim.
      </p>
      <p>If you are not sure who your finance company is, you can:</p>
      <ol>
        <li class="list-decimal">
          Check with your bank to see who your monthly payments were sent to, or
        </li>
        <li class="list-decimal">
          Check your credit report for free with any of the major credit
          referencing agencies and identify the lender / finance company
        </li>
      </ol>
    </div>

    {#if errors?.general}<div class="text-error">{errors.general}</div>{/if}

    <FloatingFooter>
      <div class="flex justify-between">
        <button
          class="btn btn-primary"
          type="submit"
          disabled={selectedOption === "no"}>Done</button
        >
        <button class="btn btn-ghost" form={closeDialogId}>Cancel</button>
      </div>
    </FloatingFooter>
  </div>
</Form>

<datalist id={financeCompanyOptionsId}>
  {#each financeCompanyOptions as financeCompanyOption}
    <option value={financeCompanyOption}></option>
  {/each}
</datalist>

<form method="dialog" id={closeDialogId}></form>
