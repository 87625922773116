import { writable } from "svelte/store";

/*
 * A writable store that can be set to any value but always sets itself back to
 * its `initial` value `delay` milliseconds after the last time it was set.
 */
export default function revert<T>(initial = null, delay = 3_000) {
  const store = writable<T>(initial)
  const setInitial = ()=>store.set(initial)
  let timeout: number
  return {
    set: (v : any)=>{
      clearTimeout(timeout)
      store.set(v)
      timeout = setTimeout(setInitial, delay)
    },
    subscribe: store.subscribe
  }
}