import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static values = {
    smallScreenOnly: Boolean,
    smallScreenBreakpoint: { type: Number, default: 1050 },
  }

  static targets = ["scrollToElement"]

  connect() {
    // Listen to Turbo Drive load events
    document.addEventListener('turbo:load', this.scrollIfNeeded)
  }

  disconnect() {
    // Clean up the event listener when the controller disconnects
    document.removeEventListener('turbo:load', this.scrollIfNeeded)
  }

  scrollIfNeeded = () => {
    // don't auto scroll on large screens
    if (this.smallScreenOnlyValue && window.innerWidth > this.smallScreenBreakpointValue) {
      return
    }

    if (this.hasScrollToElementTarget) {
      this.scrollToTarget()
    }
  }

  scrollToTarget() {
    this.scrollToElementTarget.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}
