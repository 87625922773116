import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="localize-date"
export default class extends Controller {
  static targets = ["date"];

  static values = {
    month: { String, default: "short" },
    day: { String, default: "numeric" },
    year: { Boolean, default: true },
    time: { Boolean, default: false },
  };

  connect() {
    const dateInput = this.element.innerHTML;
    const dateOnly = dateInput.match(/^\d{4}-\d{2}-\d{2}$/);
    let date;

    if (dateOnly === null) {
      date = new Date(dateInput)
    } else {
      date = new Date(dateInput + "T00:00:00")
    }

    let options = {
      month: this.monthValue,
      day: this.dayValue,
    };
    if (this.yearValue) {
      options.year = "numeric";
    }
    if (this.timeValue) {
      (options.hour = "numeric"), (options.minute = "numeric");
    }

    this.element.innerHTML = new Intl.DateTimeFormat(undefined, options).format(
      date
    );
  }

}
