export default class XhrUpload {
  url: string
  file: File
  xhr: XMLHttpRequest
  onProgress: (event: ProgressEvent)=>{} | null

  constructor(url:string, file:File){
    this.url = url
    this.file = file
  }

  send(){
    const file = this.file;
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open("PUT", this.url, true);
    for(const [key, value] of [
      ["Content-Type", file.type],
      ["Content-Range", `bytes 0-${file.size - 1}/${file.size}`],
      ["Accept", "application/json"],
    ]) xhr.setRequestHeader(key, value);
    if(this.onProgress) xhr.upload.addEventListener("progress", this.onProgress);
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(new Error(`${xhr.statusText}: ${xhr.responseText}`));
        }
      };
      xhr.onerror = () => reject(new Error(xhr.statusText));
      xhr.send(file);
    });
  }

  cancel(){
    switch(this.xhr?.readyState){
      case XMLHttpRequest.HEADERS_RECEIVED:
      case XMLHttpRequest.LOADING:
        this.xhr.abort();
        break;
    }
    // The idea here was to delete the upload session as well, but it just causes lots of errors on the Salesforce side
    // return fetch(new Request(this.url, {method: "DELETE"}));
  }
}